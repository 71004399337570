import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VendorReqBody } from "@app/pages/vendor/+data-access/vendor-req.model";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IProperty } from "../interfaces";
import { IResponseDTO } from "../interfaces/response.interface";
import { IVendor } from "../interfaces/vendor.interface";

// TODO: Remove all unrelated vendor api method from here
@Injectable({
  providedIn: "root",
})
export class VendorService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public searchVendors(params: any) {
    return this.http.post<IResponseDTO<IVendor[]>>(
      `${environment.apiUrl}/vendors/search`,
      {
        ...params,
        withCredentials: true,
      }
    );
  }

  public getVendorsPagination(
    property: string = "",
    limit: number = 10,
    page: number = 1,
    sort: string = "name",
    order: string = "asc"
  ): Observable<IResponseDTO<IVendor[]>> {
    const body = {
      limit,
      order,
      page,
      sort,
    };

    return this.http.post<IResponseDTO<IVendor[]>>(
      `${environment.apiUrl}/vendors/search`,
      body,
      {
        withCredentials: true,
        headers: {
          property,
        },
      }
    );
  }

  public getDevelopers(
    all: boolean = false
  ): Observable<IResponseDTO<IVendor[]>> {
    let url = `${this.apiUrl}/developers`;
    if (all) {
      url += "/all";
    }
    return this.http.get<IResponseDTO<IVendor[]>>(url, {
      withCredentials: true,
    });
  }

  public getEntities(
    property: string = "",
    all: boolean = false
  ): Observable<IResponseDTO<IVendor[]>> {
    let url = `${this.apiUrl}/entities`;
    if (all) {
      url += "/all";
    }
    return this.http.get<IResponseDTO<IVendor[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  public getVendor(id: string): Observable<IVendor> {
    return this.http.get<IVendor>(`${this.apiUrl}/vendors/${id}`, {
      withCredentials: true,
    });
  }

  public getVendors(
    property: string = "",
    all: boolean = false
  ): Observable<IResponseDTO<IVendor[]>> {
    let url = `${this.apiUrl}/vendors`;
    if (all) {
      url += "/all";
    }
    return this.http.get<IResponseDTO<IVendor[]>>(url, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  public addVendor(body: VendorReqBody.AddOne) {
    return this.http.post<any>(`${this.apiUrl}/vendors`, body, {
      withCredentials: true,
    });
  }

  public patchVendor(id: string, body: VendorReqBody.PatchOne) {
    return this.http.patch(`${this.apiUrl}/vendors/${id}`, body, {
      withCredentials: true,
    });
  }

  public deleteVendor(id: string) {
    return this.http.delete(`${this.apiUrl}/vendors/${id}`, {
      withCredentials: true,
    });
  }

  public getListProperties(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/properties`, {
      withCredentials: true,
    });
  }

  public searchPropertyByName(
    name: string
  ): Observable<IResponseDTO<IProperty[]>> {
    let body = { filters: { name }, limit: 9999 };
    return this.http.post<IResponseDTO<IProperty[]>>(
      `${this.apiUrl}/properties/search`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  public getPropertyCustomers(
    property: string = "",
    limit: number = 10,
    page: number = 1
  ): Observable<IResponseDTO<IProperty[]>> {
    const body = {
      limit,
      page,
    };

    let url = `${this.apiUrl}/vendors/property-customers/${property}`;
    return this.http.get<IResponseDTO<IProperty[]>>(url, {
      withCredentials: true,
    });
  }
}
